import * as React from "react"
import { Link, graphql } from "gatsby"

import { Splide, SplideSlide } from '@splidejs/react-splide'
import '../../node_modules/@splidejs/react-splide/dist/css/splide.min.css'
import gwbCover from '../images/gwb-cover.png'

import Layout from "../components/layout"
import Seo from "../components/seo"

const EpisodesPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  const posts = data.webiny.listPost.data

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="All posts" />
        <p>
          {/* No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js). */}
          No blog posts found.
        </p>
      </Layout>
    )
  }
  
  return (
    <Layout location={location} title={siteTitle} className="">
      <Seo title="Episodes" />

      <div className="relative overflow-hidden">
        <div className="pt-16 pb-80 sm:pt-24 sm:pb-40 lg:pt-40 lg:pb-48">
          <div className="relative mx-auto sm:px-6 sm:static">
            <div className="">
              <h1 className="tracking-tight text-white sm:tracking-tight text-3xl lg:text-6xl knockoutFont uppercase">EPISODES</h1>
            </div>
            <div className="py-5">
              <div className="grid">
                <div className="overflow-auto">
                  <Splide 
                    options={ {
                      rewind: true,
                      type   : 'loop',
                      pagination: false,
                      width : '100%',
                      perPage: 4,
                      drag   : 'free',
                      breakpoints: {
                        1024: {
                          perPage: 2,
                        },
                        768: {
                          perPage: 1,
                          snap: false,
                        },
                      },
                    } }
                    aria-label="latest-projects">
                      {posts.map(post => {
                      return (post.visibility) ?
                          <SplideSlide key={post.entryId} className="py-2">
                            <div className="w-full">

                            <div class="">
                              <div class="rounded-lg shadow-lg bg-black max-w-sm">
                                <Link to={post.slug} itemProp="url">
                                  {post.video ? 
                                  <img src={post.image} />
                                : <img src={gwbCover} className="" /> }
                                </Link>
                                <div class="p-6">
                                  <h5 class="text-white text-l mb-2">{post.title}</h5>
                                  <p class="text-sm mb-4">
                                    {post.description}
                                  </p>                           
                                </div>
                              </div>
                            </div>

                            </div>
                          </SplideSlide>
                          :
                          null
                      })}
                  </Splide>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </Layout>
  )
}

export default EpisodesPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    webiny {
      listPost(sort: createdOn_ASC) {
        data {
          id
          entryId
          title
          slug
          description
          createdOn
          image
          video
          visibility
          featurePost
        }
      }
    }
  }
`
